import { handleErrorWithSentry } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';
import { baseSentryConfig } from '@lib/sentry';
import { get } from 'svelte/store';
import { gameState } from '@state/game.state';

export const initSentryClient = () => {
	Sentry.init({
		...baseSentryConfig,
		// This sets the sample rate to be 10%. You may want this to be 100% while
		// in development and sample at a lower rate in production
		replaysSessionSampleRate: 0.1,

		// If the entire session is not sampled, use the below sample rate to sample
		// sessions when an error occurs.
		replaysOnErrorSampleRate: 1.0,

		// If you don't want to use Session Replay, just remove the line below:
		integrations: [
			Sentry.replayIntegration(),
			Sentry.feedbackIntegration({
				colorScheme: 'dark',
				showEmail: false,
				showName: false,
				onFormOpen: () => {
					// eslint-disable-next-line no-console
					console.log(JSON.stringify(get(gameState), null, 2));
				}
			})
		]
	});

	// If you have a custom error handler, pass it to `handleErrorWithSentry`
	return { handleError: handleErrorWithSentry() };
};
